import { addMethod, string } from 'yup';

import { isValidPhoneNumber } from './phonenumber';
import { isValidVatNumber } from './vat-number';

declare module 'yup' {
  interface StringSchema {
    phone(): StringSchema;
    vatNumber(): StringSchema;
  }
}

addMethod(string, 'phone', function () {
  return this.test('phone', 'ongeldig telefoonnummer', (val) => (val ? isValidPhoneNumber(val) : true));
});

addMethod(string, 'vatNumber', function () {
  return this.test('vatNumber', 'ongeldig btw nummer', (val) => (val ? isValidVatNumber(val) : true));
});
