import { useField } from 'formik';

import { InputWrapper } from '../InputWrapper';
import { ISimpleSelectItem, SimpleSelect } from './SimpleSelect';

export interface ISimpleSelectFieldProps {
  labelText: string;
  name: string;
  helperText?: string;
  isDisabled?: boolean;
  items: ISimpleSelectItem[];
}

export const SimpleSelectField: React.FC<ISimpleSelectFieldProps> = (props) => {
  const { name, labelText, helperText, isDisabled, items } = props;
  const [field, meta, helpers] = useField({ name });

  return (
    <InputWrapper
      labelText={labelText}
      invalidText={meta.touched ? meta.error : undefined}
      helperText={helperText}
      noLabel
    >
      <SimpleSelect
        items={items}
        selectedItem={field.value}
        onSelect={helpers.setValue}
        onBlur={() => helpers.setTouched(true)}
        isDisabled={isDisabled}
        isInvalid={Boolean(meta.touched && meta.error)}
      />
    </InputWrapper>
  );
};
