import { useReducer } from 'react';

export interface ISelectionState {
  selectedItems: number[];
  isMultiSelect: boolean;
}

export type SelectionAction =
  | {
      type: 'select';
      index: number;
    }
  | {
      type: 'change_type';
      isMultiSelect: boolean;
    }
  | {
      type: 'reset';
    };

function selectionReducer(prevState: ISelectionState, action: SelectionAction): ISelectionState {
  switch (action.type) {
    case 'select': {
      const newSelect = new Set(prevState.selectedItems);
      if (newSelect.has(action.index)) {
        newSelect.delete(action.index);
        return {
          ...prevState,
          selectedItems: [...newSelect],
        };
      } else if (prevState.isMultiSelect) {
        newSelect.add(action.index);

        return {
          ...prevState,
          selectedItems: [...newSelect],
        };
      } else {
        return {
          ...prevState,
          selectedItems: [action.index],
        };
      }
    }
    case 'change_type': {
      let selectedItems = prevState.selectedItems;
      if (!action.isMultiSelect) {
        const lastItem = selectedItems.pop();
        if (lastItem != null) {
          selectedItems = [lastItem];
        }
      }
      return {
        selectedItems,
        isMultiSelect: action.isMultiSelect,
      };
    }
    case 'reset':
      return {
        ...prevState,
        selectedItems: [],
      };
    default:
      return prevState;
  }
}

export function useSelectionReducer(isMultiSelect: boolean) {
  return useReducer(selectionReducer, {
    selectedItems: [],
    isMultiSelect,
  });
}
