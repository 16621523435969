import React, { useCallback, useEffect, useState } from 'react';
import { AnySchema } from 'yup';

import { BaseButton, BaseButtonProps } from '../button/BaseButton';
import { FormikProvider, useFormik } from 'formik';
import { DialogContent, DialogRoot, DialogTrigger } from './Dialog';

export interface IFormDialogProps<V> {
  resetKey?: string;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  showTrigger?: boolean;
  triggerText: React.ReactNode;
  triggerColor?: BaseButtonProps['color'];
  triggerVariant?: BaseButtonProps['variant'];
  triggerSize?: BaseButtonProps['size'];
  title: string;
  validationSchema?: AnySchema;
  cancelText?: string;
  submitText: string;
  onSubmit: (values: V) => void | Promise<void>;
  initialValues: V;
  children: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export function FormDialog<V>(props: IFormDialogProps<V>) {
  const {
    resetKey,
    open: openOverwrite,
    setOpen: setOpenOverwrite,
    showTrigger = true,
    triggerText,
    triggerColor,
    triggerVariant,
    triggerSize,
    title,
    cancelText = 'Cancel',
    submitText,
    children,
    initialValues,
    validationSchema,
    onSubmit,
    isDisabled,
    isLoading,
  } = props;
  const [_open, _setOpen] = useState(false);

  const open = openOverwrite ?? _open;
  const setOpen = useCallback(
    (newOpen: boolean) => {
      if (setOpenOverwrite) {
        setOpenOverwrite(newOpen);
      } else {
        _setOpen(newOpen);
      }
    },
    [_setOpen, openOverwrite],
  );

  const formikbag = useFormik({
    initialValues: initialValues as any,
    validationSchema,
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
        setOpen(false);
      } catch (err) {
        // do nothing...
      }
    },
  });

  useEffect(() => {
    if (resetKey) {
      formikbag.setValues(initialValues);
    }
  }, [resetKey]);

  const { handleSubmit, isSubmitting } = formikbag;
  return (
    <DialogRoot open={open} onOpenChange={setOpen} showTrigger={showTrigger}>
      <DialogTrigger>
        <BaseButton
          isLoading={formikbag.isSubmitting || isLoading}
          disabled={formikbag.isSubmitting || isDisabled}
          size={triggerSize}
          color={triggerColor}
          variant={triggerVariant}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();

            setOpen(true);
          }}
        >
          {triggerText}
        </BaseButton>
      </DialogTrigger>

      <DialogContent className="dialog-content">
        <h1 className="heading-one mb-4">{title}</h1>

        <FormikProvider value={formikbag}>
          <form className="flex flex-col" onSubmit={handleSubmit}>
            {children}

            <div className="flex justify-between">
              <div>
                <BaseButton
                  type="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    setOpen(false);
                  }}
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  {cancelText}
                </BaseButton>
              </div>
              <div>
                <BaseButton
                  type="submit"
                  variant="primary"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    handleSubmit();
                  }}
                >
                  {submitText}
                </BaseButton>
              </div>
            </div>
          </form>
        </FormikProvider>
      </DialogContent>
    </DialogRoot>
  );
}
