import { Helmet } from 'react-helmet-async';

export interface Props {
  title: string;
}

export const PageHeader: React.FC<Props> = (props) => {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content="Example description" />
    </Helmet>
  );
};
