import { isValidPhoneNumber as _isValidPhoneNumber, format, parse } from 'libphonenumber-js';

export function isValidPhoneNumber(val: string): boolean {
  return _isValidPhoneNumber(val, 'BE');
}

export function formatPhoneNumber(val: string, pretty: boolean = false): string {
  const parsed = parse(val, 'BE');
  if (pretty) {
    return format(parsed, 'INTERNATIONAL');
  } else {
    return format(parsed, 'E.164');
  }
}
