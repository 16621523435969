import { useParams } from 'react-router-dom';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { nullthrows } from '../../../utils/invariant';
import { IFileEntry, UploadForm } from '../components/UploadForm';
import { ALLOWED_MIMETYPES } from '../constants';
import { useTeam } from '@/app/team/context/TeamContext';
import { useExplorerTree } from '../../explorerTree/contexts/ExplorerContext';
import { SpinnerBlock } from '../../../components/Spinner';

export const UploadDocumentsPage = () => {
  const { collectionId: _collectionId } = useParams<{ collectionId: string }>();
  const collectionId = nullthrows(_collectionId, 'Collection id not defined');
  const { team } = useTeam();
  const { tree: explorerTree } = useExplorerTree();

  const uploadFile = async (file: IFileEntry) => {
    try {
      const formData = new FormData();
      formData.set('name', file.name);
      formData.set('file', file.file);
      formData.set('collectionId', collectionId);

      const result = await fetch('/api/document/upload', {
        body: formData,
        method: 'post',
      });

      const text = await result.text();
      if (!result.ok) {
        console.error(text);
        throw new Error('Upload failed');
      }
    } catch (err) {
      throw err;
    }
  };

  const collectionNode = explorerTree.getCollectionNode(collectionId);
  if (!collectionNode) {
    if (explorerTree.isSyncing) {
      return <SpinnerBlock message="Loading..." className="h-screen" />;
    } else {
      return <div>Collection not found</div>;
    }
  }

  return (
    <div className="page-content">
      <PageHeader title="Upload" />

      <div className="mb-4">
        <Breadcrumb
          items={[
            {
              name: collectionNode.name,
              to: '..',
            },
            {
              name: 'Upload',
            },
          ]}
        />
      </div>

      <div className="card">
        <UploadForm
          uploadFile={uploadFile}
          accept={[...ALLOWED_MIMETYPES.values()].join(', ')}
          allowMultiple={true}
          maxFileSize={100 * 1024 * 1024}
        />

        <div className="text-sm">
          Supported file formats: jpg, jpeg, png, pdf, doc, docx, xls, xlsx, txt, html, msg and eml
        </div>
      </div>
    </div>
  );
};
