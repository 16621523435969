import classNames from '@/utils/classnames';
import React, { useEffect, useRef, useState } from 'react';
import { CheckIcon, FileIcon, PlusIcon, XIcon } from 'lucide-react';
import { AlertTriangle } from 'lucide-react';
import { useBlocker } from 'react-router-dom';
import { createId } from '@paralleldrive/cuid2';
import toast from 'react-hot-toast';
import PQueue from 'p-queue';

import { Button } from '../../../components/button/Button';
import { getDisplayError } from '../../../utils/get-display-error';
import { formatBytes } from '../../../utils/text';
import { Spinner } from '../../../components/Spinner';

export enum FileEntryStatus {
  Idle,
  Uploading,
  Success,
  Error,
}

export interface IFileEntry {
  id: string;
  file: File;
  name: string;
  status: FileEntryStatus;
}

export interface IUploadFormProps {
  maxFileSize?: number;
  uploadFile: (file: IFileEntry) => Promise<void>;
  disabled?: boolean;
  accept?: string;
  allowMultiple?: boolean;
}

const WARNING_MSG =
  'Upload is in progress, closing the page might cause data loss. Are you sure you want to leave this page?';

export const UploadForm: React.FC<IUploadFormProps> = (props) => {
  const { maxFileSize, uploadFile, disabled, accept, allowMultiple } = props;
  const inputEl = useRef<HTMLInputElement | null>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [files, setFiles] = useState<IFileEntry[]>([]);
  const [isUploading, _setIsUploading] = useState<boolean>(false);
  const isUploadingRef = useRef<boolean>(isUploading);

  useBlocker(
    React.useCallback(() => {
      return isUploading ? !window.confirm(WARNING_MSG) : false;
    }, [isUploading]),
  );

  const setIsUploading = (newValue: boolean) => {
    isUploadingRef.current = newValue;
    _setIsUploading(newValue);
  };

  useEffect(() => {
    const cancelCloseHandler = (event: BeforeUnloadEvent) => {
      if (!isUploadingRef.current) return;

      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';

      return WARNING_MSG;
    };
    window.addEventListener('beforeunload', cancelCloseHandler);

    return () => {
      window.removeEventListener('beforeunload', cancelCloseHandler);
    };
  }, [true]);

  const uploadFiles = () => {
    if (isUploading) {
      return;
    }

    setIsUploading(true);
    try {
      const handleUploadFile = async (file: IFileEntry) => {
        if (maxFileSize && file.file.size > maxFileSize) {
          throw new Error('File is too large');
        }

        await uploadFile(file);
      };

      const filteredFiles = [...files].filter((f) => {
        return f.status !== FileEntryStatus.Success;
      });

      const queue = new PQueue({ autoStart: true, concurrency: 5 });
      for (const file of filteredFiles) {
        queue.add(() =>
          handleUploadFile(file)
            .then(() => {
              setFiles((prev) => {
                const cloned = [...prev];
                const foundFile = cloned.find((f) => f.id === file.id);
                if (foundFile) {
                  foundFile.status = FileEntryStatus.Success;
                  return cloned;
                } else {
                  return prev;
                }
              });
            })
            .catch((err) => {
              setFiles((prev) => {
                const cloned = [...prev];
                const foundFile = cloned.find((f) => f.id === file.id);
                if (foundFile) {
                  foundFile.status = FileEntryStatus.Error;
                  return cloned;
                } else {
                  return prev;
                }
              });

              toast(`Failed to upload file: ${getDisplayError(err)}`);
            }),
        );
      }

      setFiles((files) => {
        return files.map((file) => {
          return {
            ...file,
            status: file.status === FileEntryStatus.Success ? FileEntryStatus.Success : FileEntryStatus.Uploading,
          };
        });
      });

      queue.onIdle().finally(() => {
        setIsUploading(false);
        toast('Upload complete');
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleAddFiles = (inputFiles: File[]) => {
    const mappedInputFiles = inputFiles.map((file) => {
      return {
        id: createId(),
        file,
        name: file.name,
        status: FileEntryStatus.Idle,
      };
    });
    if (allowMultiple) {
      setFiles((prev) => {
        return [...prev, ...mappedInputFiles];
      });
    } else {
      setFiles((prev) => {
        if (mappedInputFiles.length > 0) {
          return [mappedInputFiles[0]!];
        } else {
          return prev;
        }
      });
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    let inputFiles = e.target.files;
    if (!inputFiles) {
      return;
    }

    handleAddFiles([...inputFiles]);

    e.target.value = '';
  };

  const openUploadDialog = () => {
    if (inputEl.current) {
      // @ts-ignore
      inputEl.current.dispatchEvent(new MouseEvent('click'));
    }
  };

  const handleClick = () => {
    if (disabled || files.length) return;

    openUploadDialog();
  };

  const dragEnter = (e: React.DragEvent<HTMLInputElement>) => {
    if (disabled) return;
    e.stopPropagation();
    e.preventDefault();

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const dragOver = (e: React.DragEvent<HTMLInputElement>) => {
    if (disabled) return;
    e.stopPropagation();
    e.preventDefault();
  };

  const dragLeave = (e: React.DragEvent<HTMLInputElement>) => {
    if (disabled) return;
    e.stopPropagation();
    e.preventDefault();

    setDragging(false);
  };

  const drop = (e: React.DragEvent<HTMLInputElement>) => {
    if (disabled) return;
    e.stopPropagation();
    e.preventDefault();

    handleAddFiles([...e.dataTransfer.files]);
  };

  const isLoading = isUploading || files.some((f) => f.status === FileEntryStatus.Uploading);
  return (
    <div>
      <div
        className={classNames('w-full border-dashed border-gray-400 text-gray-400 border-2 rounded', {
          'cursor-pointer hover:border-gray-500': !disabled && !files.length,
          'border-gray-500': dragging,
        })}
        onClick={handleClick}
      >
        <div className="w-full" onDragEnter={dragEnter} onDragOver={dragOver} onDragLeave={dragLeave} onDrop={drop}>
          {files.length ? (
            <div className="w-full h-full">
              {files.map((file, idx) => {
                return (
                  <div className="flex items-center py-2" key={`file-${file.id}`}>
                    <div className="px-1 text-dark-500">
                      <FileIcon className="w-8 h-8" />
                    </div>
                    <input
                      className="flex-1 p-2 bg-gray-200 rounded text-dark-500"
                      value={file.name}
                      onChange={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();

                        setFiles((files) => {
                          files[idx]!.name = evt.target.value;
                          return [...files];
                        });
                      }}
                    />

                    <div>
                      {file.status === FileEntryStatus.Uploading ? (
                        <Spinner size={8} className="mx-2 text-dark-500" />
                      ) : file.status === FileEntryStatus.Error ? (
                        <AlertTriangle className="mx-2 h-8 w-8 text-red-600" />
                      ) : file.status === FileEntryStatus.Success ? (
                        <CheckIcon className="mx-2 h-8 w-8 text-green-600" />
                      ) : null}
                    </div>

                    <div className="px-2">
                      <Button
                        variant="default"
                        onTrigger={(evt) => {
                          evt.preventDefault();
                          evt.stopPropagation();

                          const clonedArr = [...files];
                          clonedArr.splice(idx, 1);
                          setFiles(clonedArr);
                        }}
                      >
                        <XIcon className="button-icon" />
                      </Button>
                    </div>
                  </div>
                );
              })}

              <div className="flex justify-center items-center my-4">
                <Button
                  variant="default"
                  onTrigger={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();

                    openUploadDialog();
                  }}
                >
                  Add Files
                </Button>
              </div>
            </div>
          ) : (
            <div className="w-full my-10 flex items-center justify-center">
              <div className="flex flex-col items-center">
                <div className="mb-4">{<PlusIcon className="w-8 h-8" />}</div>
                <div>Click here to add files</div>
              </div>
            </div>
          )}
        </div>
      </div>

      <input
        type="file"
        multiple={Boolean(allowMultiple)}
        accept={accept}
        onChange={handleFileUpload}
        className="hidden"
        ref={inputEl}
      ></input>

      <div className="my-4 flex justify-between">
        <div className="text-sm">
          {maxFileSize != null && `Files cannot be larger than ${formatBytes(maxFileSize)}`}
        </div>

        <Button variant="primary" onTrigger={uploadFiles} isDisabled={isLoading} isLoading={isLoading}>
          Upload
        </Button>
      </div>
    </div>
  );
};
