import { FolderInputIcon } from 'lucide-react';
import { useState } from 'react';
import toast from 'react-hot-toast';

import { DocumentPickerDialogWithTrigger } from './DocumentPicker';
import { EXPLORER_ROOT_ID } from '../../explorerTree/explorer-tree';
import { useExplorerTree } from '../../explorerTree/contexts/ExplorerContext';
import { BodyType as MoveDocumentPayload } from '../endpoints/MoveDocumentEndpoint';
import { BodyType as MoveDocumentCollectionPayload } from '../../documentCollection/endpoints/MoveDocumentCollectionEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { useTeam } from '../../team/context/TeamContext';

export interface IMoveDialogProps {
  selectedCollectionIds: string[];
  selectedDocumentIds: string[];
  onComplete: () => void;
}

export const MoveDialog: React.FC<IMoveDialogProps> = (props) => {
  const { selectedCollectionIds, selectedDocumentIds, onComplete } = props;
  const { team } = useTeam();
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { tree: explorerTree } = useExplorerTree();

  return (
    <DocumentPickerDialogWithTrigger
      triggerText={<FolderInputIcon className="button-icon" />}
      isLoading={isFetching}
      isDisabled={!selectedCollectionIds.length && !selectedDocumentIds.length}
      title="Move to selected folder"
      open={showMoveDialog}
      onOpenChange={setShowMoveDialog}
      multiSelect={false}
      onlyCollections={true}
      showRoot={selectedDocumentIds.length === 0}
      onSubmit={async (selectedNodes) => {
        setIsFetching(true);

        try {
          const selectedNode = selectedNodes.pop();
          if (!selectedNode) {
            throw new Error('No collection selected');
          }

          const node = explorerTree.entries.get(selectedNode.id);
          const selectedId = node?.collection?.id ?? null;
          if (!selectedId && selectedNode.id !== EXPLORER_ROOT_ID) {
            throw new Error('No collection selected');
          }

          if (!selectedId && selectedDocumentIds.length > 0) {
            throw new Error('Cannot move documents to root');
          }

          for (const selectedDocumentId of selectedDocumentIds) {
            const payload: MoveDocumentPayload = {
              documentId: selectedDocumentId,
              newParentCollectionId: selectedId!,
            };
            await fetchEndpointData('/api/v1/document/move', {
              method: 'POST',
              body: payload,
            });
          }

          for (const selectedCollectionId of selectedCollectionIds) {
            const payload: MoveDocumentCollectionPayload = {
              teamId: team.id,
              documentCollectionId: selectedCollectionId,
              newParentCollectionId: selectedId,
            };
            await fetchEndpointData<MoveDocumentCollectionPayload>(`/api/v1/document-collection/move`, {
              method: 'POST',
              body: payload,
            });
          }

          onComplete();
          setShowMoveDialog(false);

          toast.success('Moved items to the new collection successfully');
        } catch (err) {
          console.error(err);

          toast.error('Failed to move the items to the new collection');
        }

        setIsFetching(false);
      }}
    />
  );
};
