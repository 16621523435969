import { useEffect, useState } from 'react';

export interface DelayProps {
  ms: number;
  before?: React.ReactElement;
  after: React.ReactElement;
}

export const Delay: React.FC<DelayProps> = (props) => {
  const { ms, before = null, after } = props;

  const [show, setShow] = useState(false);
  useEffect(() => {
    let timeout = setTimeout(() => setShow(true), ms);
    return () => {
      clearTimeout(timeout);
    };
  }, [ms]);

  if (!show) {
    return before;
  } else {
    return after;
  }
};
